import { render, staticRenderFns } from "./IncomeExpenses.vue?vue&type=template&id=17b42c46&lang=pug&"
import script from "./IncomeExpenses.vue?vue&type=script&lang=js&"
export * from "./IncomeExpenses.vue?vue&type=script&lang=js&"
import style0 from "./IncomeExpenses.vue?vue&type=style&index=0&id=17b42c46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports